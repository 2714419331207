<template>
  <div>
    <div
      class="flex flex-between"
      style="max-width: 972px; margin-bottom: 20px"
    >
      <div>
        <label>置顶时长及价格</label>
        <label class="note">
          <i class="el-icon-warning-outline"></i>
          <span>置顶时长最少设置两个，最多六个</span>
        </label>
      </div>
      <div>
        <el-button type="primary" @click="addConfig">添加</el-button>
      </div>
    </div>
    <div>
      <el-table
        border
        style="max-width: 972px"
        :data="config"
        id="sortTable"
        row-key="id"
        v-loading="loading"
      >
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <!--                <el-table-column width="100px" label="序号">-->
        <!--                    <template slot-scope="scope">{{scope.$index + 1}}</template>-->
        <!--                </el-table-column>-->
        <el-table-column prop="id" width="100px" label="排序">
          <template slot-scope="scope">
            <i
              class="el-icon-sort cursor-pointer my-handle"
              style="font-size: 20px; vertical-align: middle; cursor: pointer"
            ></i>
          </template>
        </el-table-column>
        <el-table-column prop="hour" label="置顶时长（小时）" width="200">
          <template slot-scope="scope">
            <div
              class="w-100 input-td"
              @mouseenter="showInput(scope.$index)"
              @mouseleave="hideInput()"
            >
              <el-input-number
                style="width: 150px"
                v-if="input_index === scope.$index"
                v-model="scope.row.hour"
                :min="1"
              ></el-input-number>
              <div v-else>{{ scope.row.hour }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="价格（元）">
          <template slot-scope="scope">
            <div
              class="w-100 input-td"
              @mouseenter="showInput(scope.$index)"
              @mouseleave="hideInput()"
              v-if="scope.row.pay_type === 0"
            >
              <el-input
                v-if="input_index === scope.$index"
                v-model="scope.row.price"
                min="0"
              ></el-input>
              <div v-else>{{ scope.row.price }}</div>
            </div>
            <div
              v-else
              @mouseenter="showInput(scope.$index)"
              @mouseleave="hideInput()"
            >
              -
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="积分">
          <template slot-scope="scope">
            <div
              class="w-100 input-td"
              @mouseenter="showInput(scope.$index)"
              @mouseleave="hideInput()"
              v-if="scope.row.pay_type === 1"
            >
              <el-input
                v-if="input_index === scope.$index"
                v-model="scope.row.price"
                min="0"
              ></el-input>
              <div v-else>{{ scope.row.price }}</div>
            </div>
            <div
              v-else
              @mouseenter="showInput(scope.$index)"
              @mouseleave="hideInput()"
            >
              -
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pay_type" label="支付方式">
          <template slot-scope="scope">
            <div
              @mouseenter="showInput(scope.$index)"
              @mouseleave="hideInput()"
            >
              <el-select
                v-if="input_index === scope.$index"
                v-model="scope.row.pay_type"
                @focus="showInput(scope.$index, 1)"
                @visible-change="hideInput($event, scope.$index)"
              >
                <el-option label="金额" :value="0"></el-option>
                <el-option label="积分" :value="1"></el-option>
              </el-select>
              <div v-else>{{ scope.row.pay_type ? '积分' : '金额' }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="text" @click="setRecommend(scope.$index)"
              >{{ scope.row.recommend ? '取消推荐' : '设为推荐' }}
            </el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button type="text" @click="removeSingle(scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="添加置顶时长及价格"
      :visible="showAddCategory"
      width="504px"
      class="dialog-vertical"
      @close="closeAdd"
    >
      <el-form
        :model="categoryForm"
        size="medium"
        label-width="100px"
        ref="categoryForm"
      >
        <el-form-item label="付款方式">
          <el-radio v-model="categoryForm.pay_type" :label="0">金额</el-radio>
          <el-radio v-model="categoryForm.pay_type" :label="1">积分</el-radio>
        </el-form-item>
        <el-form-item label="置顶时长" prop="hour">
          <el-input
            v-model="categoryForm.hour"
            placeholder="请填写置顶时长"
          ></el-input>
          小时
        </el-form-item>
        <el-form-item
          v-if="categoryForm.pay_type == 0"
          label="价格"
          prop="price"
        >
          <el-input
            v-model="categoryForm.price"
            placeholder="请填写价格"
          ></el-input>
          价格
        </el-form-item>
        <el-form-item
          v-if="categoryForm.pay_type == 1"
          label="积分"
          prop="pay_type"
        >
          <el-input
            v-model="categoryForm.price"
            placeholder="请填写积分"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" @click="closeAdd">取消</el-button>
        <el-button size="small" type="primary" @click="handleAddCategory()"
          >确认</el-button
        >
      </template>
    </el-dialog>
    <FixedActionBar>
      <el-button type="primary" @click="saveConfig('config')">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import { setTopTimeConfig, getTopTimeConfig } from '../api/circle-top'
import FixedActionBar from '@/base/layout/FixedActionBar'
import Sortable from '../../page-renovation/js/Sortable.min'
export default {
  components: {
    FixedActionBar,
  },
  data() {
    return {
      loading: false,
      config: [],
      input_index: -1,
      focus_index: -1,
      showAddCategory: false,
      categoryForm: {},
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.drag()
    })
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      this.loading = true
      getTopTimeConfig()
        .then((res) => {
          this.config = res.data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    addConfig() {
      if (this.config.length >= 6) {
        // 已经有六条数据了，不可添加
        this.$message.info('最多只能添加六个置顶时长')
        return
      }
      this.showAddCategory = true
      // let timestamp = new Date().getTime();
      this.categoryForm = {
        sort: '',
        recommend: 0,
        price: '',
        hour: '',
        pay_type: 0,
      }
    },
    closeAdd() {
      this.showAddCategory = false
    },
    handleAddCategory(e) {
      this.config.push(this.categoryForm)
      this.showAddCategory = false
    },
    // 拖动
    drag() {
      const el = document.querySelectorAll(
        '#sortTable .el-table__body > tbody'
      )[0]
      Sortable.create(el, {
        disabled: false,
        handle: '.my-handle',
        ghostClass: 'sortable-ghost',
        setData: function (dataTransfer) {},
        onEnd: (e) => {
          let arr = this.config // 获取表数据
          arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]) // 数据处理
          this.$nextTick(() => {
            this.config = []
            arr.forEach((item, index) => {
              this.config.push(item)
            })
          })
        },
      })
    },
    showInput(i, bol) {
      this.input_index = i
      if (bol) {
        this.focus_index = i
      } else {
        this.focus_index = -1
      }
    },
    hideInput(e, i) {
      if (i >= 0) {
        this.focus_index = i
      }
      if (e === false) {
        this.input_index = -1
      }
      if (this.focus_index === -1) {
        this.input_index = -1
      }
    },
    setRecommend(i) {
      if (this.config[i].recommend) {
        // 取消推荐
        this.config[i].recommend = 0
      } else {
        // 设为推荐
        this.config[i].recommend = 1
        let recommendObj = this.config.splice(i, 1)
        this.config.unshift(recommendObj[0])
      }
    },
    removeSingle(i) {
      if (this.config.length <= 2) {
        // 只剩两个置顶，不能删除
        this.$message.error('置顶时长最少设置两个，不能删除')
        return
      }
      this.config.splice(i, 1)
    },
    saveConfig() {
      this.loading = true
      setTopTimeConfig({ config: this.config })
        .then((res) => {
          this.$message.success(res.msg)
          this.getDetail()
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.note {
  color: #c0c4cc;
  font-size: 14px;
  margin-left: 20px;
}
</style>
