import api from "@/base/utils/request";

//获取
export const setTopTimeConfig = data => {
    return api({
        url: "/admin/cyc_circle/Settings/setTopTimeConfig",
        method: "post",
        data
    });
};
//保存
export const getTopTimeConfig = data => {
    return api({
        url: "/admin/cyc_circle/Settings/getTopTimeConfig",
        method: "post",
        data
    });
};
